import React from 'react'
import { PortfolioNew } from './Portfolio_new'

export const Portfolio = () => {
  return (
    <div className="portfolio-flex">
      <PortfolioNew />
    </div>
  )
}
